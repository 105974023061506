<template>
  <TransitionRoot as="template" :show="open">
    <Dialog
      as="div"
      static
      class="fixed inset-0 overflow-hidden z-50"
      :open="open"
    >
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild
          as="template"
          enter="ease-in-out duration-500"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in-out duration-500"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="absolute inset-0 bg-gray-900 bg-opacity-90 transition-opacity"
          />
        </TransitionChild>
        <div class="fixed inset-y-0 right-0 pl-10 flex">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="relative w-screen" :class="size">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute cursor-pointer top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4"
                >
                  <button
                    class="rounded-md cursor-pointer text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                    @click="closePanel"
                  >
                    <span class="sr-only">Close panel</span>
                    <XIcon class="h-8 w-8 cursor-pointer" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <div
                class="h-full flex flex-col py-3 bg-white shadow-xl overflow-y-scroll dark:bg-dark-6"
              >
                <div class="px-4 sm:px-6">
                  <DialogTitle
                    class="text-lg font-medium text-gray-900 dark:text-white flex flex-col sm:flex-row"
                  >
                    <slot name="title"></slot>
                  </DialogTitle>
                </div>
                <div class="mt-6 relative flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0 px-4 sm:px-6">
                    <main>
                      <slot></slot>
                    </main>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
export default {
  name: "SlideOver",
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "max-w-3xl", //max-w-5xl
    },
  },
  setup(props) {
    let panel = ref(props.open);
    return {
      panel,
    };
  },
  computed: {
    slideOverOpen() {
      return this.$store.state.main.slideOverOpen;
    },
  },
  methods: {
    closePanel() {
      this.$emit("close", false);
      this.panel = false;
    },
  },
};
</script>
