<template>
  <!-- BEGIN: Slide Over Modal Content -->
  <div
    :id="id"
    class="modal modal-slide-over"
    data-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <a
          data-dismiss="modal"
          href="javascript:"
          class="rounded-md cursor-pointer text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white ring-2 ring-gray-300"
        >
          <span class="sr-only">Close panel</span>
          <XIcon class="w-8 h-8" />
        </a>
        <!-- BEGIN: Slide Over Header -->
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            <slot v-if="$slots.title" name="title"></slot>
          </h2>
          <slot v-if="$slots.buttonHeader" name="buttonHeader"></slot>
        </div>
        <!-- END: Slide Over Header -->
        <!-- BEGIN: Slide Over Body -->
        <div class="modal-body">
          <main>
            <slot></slot>
          </main>
        </div>
        <!-- END: Slide Over Body -->
        <!-- BEGIN: Slide Over Footer -->
        <div class="modal-footer text-right w-full">
          <button
            type="button"
            data-dismiss="modal"
            class="btn btn-outline-secondary w-20 mr-1"
          >
            Close
          </button>
          <slot v-if="$slots.buttonFooter" name="buttonFooter"></slot>
        </div>
        <!-- END: Slide Over Footer -->
      </div>
    </div>
  </div>
  <!-- END: Slide Over Modal Content -->
</template>

<script>
import { ref } from "vue";
import { XIcon } from "@heroicons/vue/outline";
export default {
  name: "SlideOverModal",
  components: {
    XIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "slide-over",
    },
    modalSize: {
      type: String,
      default: "modal-lg",
    },
  },
  setup(props) {
    let panel = ref(props.open);
    return {
      panel,
    };
  },
  methods: {
    closePanel() {
      this.$emit("close", false);
      this.panel = false;
    },
    openPanel() {
      this.$emit("close", true);
      this.panel = true;
    },
  },
};
</script>
